class ScrollBar {
    constructor(el, options) {
        this.options = Object.assign(
            {
                onScrollMove: function () {}, // 滚动时调用的方法
                onScrollEnd: function () {} // 滚动结束时调用的方法
            },
            options
        )
        this.wrap = el
        this.ul = el.children[0]
        this.scroll = null // 滚动条dom

        this.scrollBarHeight = 0 // 垂直滚动条的高度
        this.scrollY = 0 // 垂直滚动条移动的距离
        this.maxScrollY = 0 // 垂直滚动条可移动最大的距离
        this.ratioY = 0 // 可视区域高度和滚动内容高度的比例
        this.timeoutId = 0 // 处理滚动和resize时的计时器
        this.inMouseDown = false // 滚动条是否处于鼠标按下状态
        this.dragStartY = 0 // 鼠标拖拽按下时的初时高度

        this.initScroll()
    }

    /**
     * 初始化滚动条
     */
    initScroll() {
        const scroll = (this.scroll = document.createElement('div'))

        Object.assign(scroll.style, {
            position: 'absolute',
            right: '2px',
            top: '0',
            width: '8px',
            background: 'rgba(0,0,0,.1)',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            borderRadius: '4px',
            transition: 'background .3s',
            visibility: 'hidden'
        })
        this.wrap.appendChild(scroll)
        this.wrap.onwheel = this.wheelHandler.bind(this)
        this.wrap.onmouseenter = this.mouseenterScrollAreaHandler.bind(this)
        this.wrap.onmouseleave = this.mouseleaveScrollAreaHandler.bind(this)
        this.resizeHandler = this.resizeHandler.bind(this)
        window.addEventListener('resize', this.resizeHandler)

        // 添加拖拽事件
        scroll.onmouseover = this.mouseoverHandler.bind(this)
        scroll.onmouseleave = this.mouseleaveHandler.bind(this)
        scroll.onmousedown = this.mousedownHandler.bind(this)
        this.mousemoveHandler = this.mousemoveHandler.bind(this)
        this.mouseupHandler = this.mouseupHandler.bind(this)
    }

    /**
     * 滚动事件
     * @param {*} e
     */
    wheelHandler(e) {
        this.scrollMove(e)

        this.options.onScrollMove(e, {
            maxScrollY: this.maxScrollY,
            scrollY: this.scrollY
        })

        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
            this.options.onScrollEnd(e, {
                maxScrollY: this.maxScrollY,
                scrollY: this.scrollY
            })
        }, 300)
    }

    /**
     * 鼠标移入滚动区域事件
     * @param {*} e
     */
    mouseenterScrollAreaHandler(e) {
        this.scroll.style.visibility = 'visible'
    }

    /**
     * 鼠标移入滚动区域事件
     * @param {*} e
     */
    mouseleaveScrollAreaHandler(e) {
        this.scroll.style.visibility = 'hidden'
    }

    /**
     * 窗口改变尺寸事件
     */
    resizeHandler() {
        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
            this.refresh()
        }, 300)
    }

    /**
     * 滚动方法
     * @param {*} e
     */
    scrollMove(e) {
        if (e.preventDefault) {
            e.preventDefault()
        }
        if (e.stopPropagation) {
            e.stopPropagation()
        }
        // chrome浏览器 单位是100  firefox单位是3  统一为100
        // WheelEvent.deltaMode  0 为像素级别 1为行级别
        // firefox 鼠标滚动的时候是行级别要换为像素级别
        let deltaY = e.deltaY
        if (e.deltaMode && e.deltaMode === 1) {
            deltaY = e.deltaY * 30
        }
        this.scrollY += deltaY
        if (this.scrollY >= this.maxScrollY) {
            this.scrollY = this.maxScrollY
        }
        if (this.scrollY <= 0) {
            this.scrollY = 0
        }
        if (this.ul) {
            this.ul.style.transform = `translate3d(0,${-this.scrollY}px,0)`
        }

        this.scroll.style.transform = `translate3d(0,${Math.floor(this.scrollY * this.ratioY)}px,0)`
    }

    /**
     * 刷新滚动条的高度
     */
    refresh() {
        // 元素不可见时，不能获取offsetHeight,获取出来的错误数据会导致错误样式
        if (this.wrap.offsetParent === null) return

        const wrapHeight = this.wrap.offsetHeight || 1
        const ulHeight = (this.ul && this.ul.offsetHeight) || 1 // 避免0/0为NaN
        this.maxScrollY = ulHeight - wrapHeight
        this.ratioY = wrapHeight / ulHeight

        if (this.ratioY < 1) {
            this.scrollBarHeight = Math.floor(this.ratioY * wrapHeight)
            Object.assign(this.scroll.style, {
                height: this.scrollBarHeight + 'px',
                display: 'block'
            })
            this.scrollMove({ deltaY: 0 })

            this.wrap.onwheel = this.wheelHandler.bind(this)
        } else {
            Object.assign(this.scroll.style, {
                display: 'none'
            })
            this.scrollMove({ deltaY: -this.maxScrollY / this.ratioY }) // 滚动到最上面，避免内容未归位
            this.wrap.onwheel = null
        }
    }

    /**
     * 滚动到相应的地方
     * @param {*} param0
     */
    scrollTo({ x, y }) {
        this.refresh()
        y /= this.ratioY
        this.scrollMove({ deltaY: y })
    }

    /**
     * 滚动到顶部
     */
    scrollToTop() {
        this.scrollTo({ y: -this.maxScrollY })
    }

    /**
     * 滚动到底部
     */
    scrollToBottom() {
        this.scrollTo({ y: this.maxScrollY })
    }

    /**
     * 设置滚动条是否选中
     * @param {*} status
     */
    setScrollSelected(status) {
        const css = status ? { background: 'rgba(0,0,0,.3)' } : { background: 'rgba(0,0,0,.1)' }
        Object.assign(this.scroll.style, css)
    }

    /**
     * 鼠标移入到滚动条
     */
    mouseoverHandler() {
        this.setScrollSelected(true)
    }

    /**
     * 鼠标移出滚动条
     */
    mouseleaveHandler() {
        if (this.inMouseDown) {
            return
        }
        this.setScrollSelected(false)
    }

    /**
     * 按下鼠标左键触发拖动事件
     */
    mousedownHandler(e) {
        const doc = document
        doc.addEventListener('mousemove', this.mousemoveHandler)
        doc.addEventListener('mouseup', this.mouseupHandler)
        doc.addEventListener('mouseleave', this.mouseupHandler)

        this.dragStartY = e.clientY
        this.inMouseDown = true
        this.setScrollSelected(true)
    }

    /**
     * 拖动滚动条
     */
    mousemoveHandler(e) {
        e.preventDefault()
        const clientY = e.clientY
        const deltaY = (clientY - this.dragStartY) / this.ratioY
        this.dragStartY = clientY
        const syntheticEvent = Object.assign({}, e, { deltaY })
        this.scrollMove(syntheticEvent)

        this.options.onScrollMove(e, {
            maxScrollY: this.maxScrollY,
            scrollY: this.scrollY
        })
    }

    /*
     * 终止拖动
     */
    mouseupHandler(e) {
        const doc = document
        doc.removeEventListener('mousemove', this.mousemoveHandler)
        doc.removeEventListener('mouseup', this.mouseupHandler)
        doc.removeEventListener('mouseleave', this.mouseupHandler)

        this.inMouseDown = false
        this.setScrollSelected(false)

        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(() => {
            this.options.onScrollEnd(e, {
                maxScrollY: this.maxScrollY,
                scrollY: this.scrollY
            })
        }, 300)
    }

    /**
     * 销毁
     */
    destroy() {
        this.wrap.onwheel = null
        this.wrap.onmouseenter = null
        this.wrap.onmouseleave = null
        this.scroll.onmouseover = null
        this.scroll.onmouseleave = null
        this.scroll.onmousedown = null
        window.removeEventListener('resize', this.resizeHandler)
    }
}

export default ScrollBar
