/*
 * @Author: Garen
 * @Date: 2025-02-27 10:54:43
 * @LastEditors: Garen
 * @LastEditTime: 2025-02-27 10:54:56
 * @Description:
 */
import Vue from 'vue'
import scrollBar from './scrollBar'

Vue.directive('scrollBar', scrollBar)
