import Vue from 'vue'
import ScrollBar from './scrollBarIndex'

function checkType(obj, type) {
    return Object.prototype.toString.call(obj).toLowerCase() === `[object ${type}]`
}

/**
 * 判断参数是不是方法
 * @param {*} obj
 */
function isFunction(obj) {
    return checkType(obj, 'function')
}

export default {
    bind(el, binding) {
        binding.value = binding.value || {}

        el.style.overflow = 'hidden'

        const options = {}

        if (isFunction(binding.value.onScrollMove)) {
            options.onScrollMove = binding.value.onScrollMove
        }
        if (isFunction(binding.value.onScrollEnd)) {
            options.onScrollEnd = binding.value.onScrollEnd
        }
        el.myScroll = new ScrollBar(el, options)

        Vue.nextTick(() => el.myScroll.refresh())
    },
    componentUpdated(el) {
        Vue.nextTick(() => el.myScroll.refresh())
    },
    unbind(el) {
        el.myScroll.destroy()
        el.myScroll = null
        delete el.myScroll
    }
}
